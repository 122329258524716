/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

	function ajaxSubmitFormData(form, form_id) {
		// console.log(show_double_chance, typeof show_double_chance);
		if (typeof form !== undefined && form != '') {
			$.ajax({
	            url: form.action,
	            type: form.method,
	            data: $(form).serialize(),
	            success: function(response) {
	                if (typeof response !== undefined && response != '') {
	                	console.log(response);
	                	response = JSON.parse(response);

	                	$('#submit-response').remove();
	                	$('#'+form_id+' .submit-container').empty().append('<p class="text-center" id="submit-response">' + response.message + '</p>');

		                // only show the double chance facebook share modal, if we are not sending the form data for the first time
		                if (response.success == 'true' || response.success === true) {
		                	$('#' + form_id).each(function(){
							    this.reset();
							});
		                } else {
		                	// $(txtError).text(response.message);
		                	// btnDownload.add(txtError).slideDown();
		                	console.log('Backend validation failed. ', response);
		                }
	                } else {
	                	// $(txtError).text(response.message);
	                	// btnDownload.add(txtError).slideDown();
	                	console.log('Error submitting form data. Please try again later or check server logs.');
	                }
	            },
				error: function(e) {
					console.log(e);
				}
	        });
		}
	}

	// validate form fields
	$('#contactForm').validate({
		ignore: "",
		// debug: true,
		submitHandler: function(form) {
			var form_id = $(form).attr('id');
			ajaxSubmitFormData(form, form_id);
		},
		rules: {
			'contactEmail': {
				required: true,
				email: true
			},
			'contactName': {
				required: true,
				minlength: 2
			},
			'contactPhone': {
				required: false
			},
			'contactPurpose': {
				required: false
			},
			'contactMessage': {
				required: true,
				minlength: 3
			}
		},
		messages: {
			'contactEmail': {
				required: "Please enter your email address.",
				email: "Please enter a valid email address.",
			},
			'contactName': {
				required: 'Please enter your name.',
				minlength: 'Please enter at least 2 characters.'
			},
			'contactMessage': {
				required: 'Please enter a message.',
				minlength: 'Please enter at least 3 characters for the message.'
			}
		}
	});

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function () {
				// JavaScript to be fired on all pages



				// Header mobile
				$(document).on("click", ".navbar-toggler", function (e) {
					e.preventDefault();
					$("body").toggleClass("menu-open");
				});
				$("#menu-collapse-main .navbar-nav>li>a").on("click", function () {
					$("body").removeClass("menu-open");
				});

				$('.owl-carousel').owlCarousel({
					loop: true,
					// nav: true,
					nav: false,
					margin: 10,
					responsiveClass: true,
					dots: true,
					autoplay:true,
    				autoplayTimeout:5000,
    				autoplayHoverPause: true,
    				smartSpeed: 1500,
				    // animateIn: 'linear',
				    // animateOut: 'linear',
					responsive: {
						0: {
							items: 1,
							nav: false
						},
						600: {
							items: 1,
							nav: false
						},
						1000: {
							items: 1,
							nav: false,
						}
					}
				});
				$('.mixed-carousel').owlCarousel({
					loop: true,
					nav: false,
					autoplay:true,
    				autoplayTimeout:5000,
					margin: 10,
					responsiveClass: true,
					dots: true,
					autoplayHoverPause: true,
					smartSpeed: 1500,
				    // animateIn: 'linear',
				    // animateOut: 'linear',
					responsive: {
						0: {
							items: 1,
							nav: false
						},
						600: {
							items: 1,
							nav: false
						},
						1000: {
							items: 1,
							nav: false,
						}
					}
				});
		        //init AOS
				AOS.init();
			},
			finalize: function () {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},
		// Home page
		'homepage': {
			init: function () {
				// JavaScript to be fired on the home page
				var myFullpage = new fullpage('#main-full', {
					verticalCentered: false,
					licenseKey: '548BA691-042C405B-A8FE9354-E6612F90',
					navigation: true,
					navigationPosition: 'right',
					navigationTooltips: ['firstSlide', 'secondSlide'],
					showActiveTooltip: false,
					slidesNavigation: true,
					slidesNavPosition: 'bottom',

					//Scrolling
					css3: true,
					scrollingSpeed: 700,
					autoScrolling: true,
					fitToSection: true,
					fitToSectionDelay: 1000,
					scrollBar: false,
					easing: 'easeInOutCubic',
					easingcss3: 'ease',
					loopBottom: false,
					loopTop: false,
					loopHorizontal: true,
					continuousVertical: false,
					continuousHorizontal: false,
					scrollHorizontally: false,
					interlockedSlides: false,
					dragAndMove: false,
					offsetSections: false,
					resetSliders: false,
					fadingEffect: false,
					normalScrollElements: '#element1, .element2',
					scrollOverflow: false,
					scrollOverflowReset: false,
					scrollOverflowOptions: null,
					touchSensitivity: 15,
					bigSectionsDestination: null,

					//Accessibility
					keyboardScrolling: true,
					animateAnchor: true,
					recordHistory: true,

					//Design
					controlArrows: true,
					paddingTop: '0',
					paddingBottom: '0',
					fixedElements: '',
					responsiveWidth: 0,
					responsiveHeight: 0,
					responsiveSlides: false,
					parallax: false,
					parallaxOptions: {type: 'reveal', percentage: 62, property: 'translate'},
					cards: false,
					cardsOptions: {perspective: 100, fadeContent: true, fadeBackground: true},

					//Custom selectors
					sectionSelector: '.fullslide',
					slideSelector: '.slide',

					lazyLoading: true,

					//events
					onLeave: function(origin, destination, direction){},
					afterLoad: function(origin, destination, direction){},
					afterRender: function(){},
					afterResize: function(width, height){},
					afterReBuild: function(){},
					afterResponsive: function(isResponsive){},
					afterSlideLoad: function(section, origin, destination, direction){},
					onSlideLeave: function(section, origin, destination, direction){}
				});
			},
			finalize: function () {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		'home': {
			init: function () {
				// JavaScript to be fired on the home page
			},
			finalize: function () {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		// About us page, note the change from about-us to about_us.
		'about_us': {
			init: function () {
				// JavaScript to be fired on the about us page
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function (func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function () {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.